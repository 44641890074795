/* You can add global styles to this file, and also import other style files */
// $se-icon-folder: "~@se/icons/fonts";
// $se-generate-font-class: "false";
// @import "~@se/icons/scss/styles";
// $se-fonts-path: "~@se/fonts/fonts";
// @import "~@se/fonts/scss/styles-technical";
// OR
// @import "~@se/fonts/scss/styles-website";

.main-body {
  background-color: #fafafa;
  margin: 0px;
  overflow-x: hidden;
}
.compAlignment{
    margin-top: 10px;
    margin-bottom: 10px;
}
.comp-title{
    margin-bottom: 25px;
}
.comp-title1{
    margin-bottom: 18px;
}
.caseBtn{
    color: white;
    text-decoration: none;
    float:right;
}
.compAlignment1{
    margin-top: 50px;
    color: #3dcd58;
    color: var(--se-primary, #3dcd58);
}
.compAlignment4{
    margin-top: 35px;
    color: #3dcd58;
    color: var(--se-primary, #3dcd58);
}
.compAlignment4CaseModule {
  margin-top: 10%;
  color: #3dcd58;
  color: var(--se-primary, #3dcd58);
}
.compAlignment4CaseModuleBanner {
//   margin-top: 5%;
  color: #3dcd58;
  color: var(--se-primary, #3dcd58);
}
.compAlignment2{
    margin-top: 35px;
    margin-bottom: 10px;
}
.compAlignment3{
    margin-top: 55px;

}
.caseList{
    color: #3dcd58;
    color: var(--se-primary, #3dcd58);
}
.caseHeader{
    margin-bottom: 10px;
}

.padTop50{
   padding-top:45px;
}
.marBottom{
    margin-bottom: -20px;
}
.pad20{
    float:right;
    padding-right:10px;
  }
  .cur
  { cursor: pointer; }

  .modal-backdrop {
    z-index: -1;
  }


@media only screen and (max-width: 768px) {
    .padTop50{
        padding-top:0px;
     }
     .padTop10{
        padding-top:10px;
     }
     .compAlignment{
        margin-top: 30px;
        margin-bottom: 10px;
    }
    .compAlignment1{
        margin-top: 35px;
        margin-bottom: 10px;
    }
    .comp-title{
        margin-top: 10px;
    }
    .compAlignment3{
        margin-top: 25px;

    }
    .compAlignment4{
        margin-top: 0px;

    }
  }

// @media only screen and (min-width: 1200px){
//     .container {
//         width: 1450px;
//     }
// }
.hidden {
  display:none;
  visibility:hidden;
}
